export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Ana Sayfa",
        to: "/dashboard",
        icon: "cil-speedometer",
        isHeader: true,
        items: [],
        langSlug: "home_page",
      },
      {
        _name: "CSidebarNavItem",
        name: "Rezervasyon Yap",
        role: "RESERVATION_CREATE",
        to: "/reservation/new",
        icon: "cil-location-pin",
        isHeader: true,
        langSlug: "make_a_reservation",
        items: [],
      },
      {
        _name: "CSidebarNavItem",
        name: "Tüm Rezervasyonlar",
        role: "RESERVATION_FIND",
        to: "/reservation/index/",
        icon: "cil-people",
        isHeader: true,
        langSlug: "all_reservations",
        items: [],
      },
      {
        _name: "CSidebarNavItem",
        name: "Son Aramalar",
        role: "RESERVATION_FIND",
        to: "/reservation/index/pending",
        icon: "cil-search",
        isHeader: true,
        langSlug: "recent_searches",
        items: [],
      },
      {
        _name: "CSidebarNavItem",
        name: "Devam Edenler",
        role: "RESERVATION_FIND",
        to: "/reservation/index/active",
        icon: "cil-location-pin",
        isHeader: true,
        langSlug: "continuning_reservations",
        items: [],
      },
      {
        _name: "CSidebarNavItem",
        name: "Gelecekler",
        role: "RESERVATION_FIND",
        to: "/reservation/index/approved",
        icon: "cil-av-timer",
        isHeader: true,
        langSlug: "future_reservations",
        items: [],
      },
      {
        _name: "CSidebarNavItem",
        name: "Geçmişler",
        role: "RESERVATION_FIND",
        to: "/reservation/index/completed",
        icon: "cil-lock-locked",
        isHeader: true,
        langSlug: "past_reservations",
        items: [],
      },
      {
        _name: "CSidebarNavItem",
        name: "İptaller",
        role: "RESERVATION_FIND",
        to: "/reservation/index/cancelled",
        icon: "cil-x-circle",
        isHeader: true,
        langSlug: "cancelled_reservations",
        items: [],
      },
      {
        _name: "CSidebarNavItem",
        name: "No-Show İptaller",
        role: "RESERVATION_FIND",
        to: "/reservation/index/noShowCancelled",
        icon: "cil-x-circle",
        isHeader: true,
        langSlug: "no_show_cancelled_reservations",
        items: [],
      },
      {
        _name: "CSidebarNavItem",
        name: "Ödeme Hatalılar",
        role: "RESERVATION_FIND",
        to: "/reservation/index/paymentError",
        icon: "cil-money",
        isHeader: true,
        langSlug: "payment_errors",
        items: [],
      },
    ],
  },
];
