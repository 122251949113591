var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "white m-2 caption text-capitalize",
                      attrs: { small: "" },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { size: "18" } }, [
                    _vm._v(" mdi-translate "),
                  ]),
                  _c("b", [_vm._v(" (" + _vm._s(_vm.selected) + ") ")]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        _vm._l(_vm.languages, function (item, index) {
          return _c(
            "v-list-item",
            { key: index, staticStyle: { "min-height": "35px" } },
            [
              _c(
                "v-list-item-title",
                {
                  staticClass: "caption text-capitalize",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.changeLocale(item.short)
                    },
                  },
                },
                [_vm._v(_vm._s(item.title))]
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }