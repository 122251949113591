var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.partner()
    ? _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "change-price-btn",
                                style:
                                  _vm.generalPriceRate != 0
                                    ? "border-color:red"
                                    : "",
                                attrs: { variant: "tonal" },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_vm._v(" " + _vm._s(_vm.$t("sidebar.rates")) + " ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1811981449
              ),
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("div", { staticClass: "container-change-price-tag" }, [
                _c(
                  "div",
                  { staticClass: "wrapper" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.$t("sidebar.rate_input_label"),
                        value: _vm.generalPriceRate,
                        type: "number",
                        prefix: "%",
                      },
                      model: {
                        value: _vm.generalPriceRate,
                        callback: function ($$v) {
                          _vm.generalPriceRate = $$v
                        },
                        expression: "generalPriceRate",
                      },
                    }),
                    _c(
                      "v-btn",
                      {
                        staticStyle: { width: "100%", "margin-top": "1rem" },
                        attrs: { variant: "tonal" },
                        on: { click: _vm.updateRate },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("sidebar.rates_save")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }