<template>
  <div>
    <v-toolbar class="align-items-center" color="#d9dbdf" style="z-index: 100">
      <v-app-bar-nav-icon
        class="v-app-bar-mobile"
        @click="$store.commit('toggleSidebarMobile')"
      ></v-app-bar-nav-icon>

      <v-app-bar-nav-icon
        class="v-app-bar-window"
        @click="$store.commit('toggleSidebarDesktop')"
      ></v-app-bar-nav-icon>

      <div class="pl-1" v-if="!$store.state.sidebarShow">
        <a href="/dashboard"
          ><svg
            version="1.1"
            id="logo-header"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 703.2 167.7"
            style="enable-background: new 0 0 703.2 167.7"
            xml:space="preserve"
          >
            <path
              class="st0"
              d="M33.9,45.9l2.2,10.7c6.5-9.1,15.2-13,26.1-13c9.3,0,17,2.8,23.9,8.9L74.3,75c-4.7-3.4-9.7-4.9-15.6-4.9 c-12.3,0-22.7,7.9-22.7,22.5v50.8H7.4V45.9H33.9z"
            />
            <path
              class="st0"
              d="M110,103.8c1.4,9.5,10.5,17.2,26.5,17.2c7.7,0,18.4-1.8,25.3-7.7l16,17.8c-10.5,10.3-27.3,14.4-41.9,14.4c-35.4,0-54.8-20.8-54.8-52c0-29.7,20-50.4,51.8-50.4c33.2,0,55,20.8,48.8,60.7H110z M156.3,83.1c-0.6-10.7-11.5-15.6-23.3-15.6 c-11.5,0-20.2,5.9-22.9,15.6H156.3z"
            />
            <path
              class="st0"
              d="M260.8,143.4V91.8c0-15-6.5-22.7-20.2-22.7c-13.2-0.2-22.1,11.3-22.1,23.9v50.4h-28.1V45.7h25.7l1,12.5c8.7-11.3,19.8-15,31.6-14.8c21.9,0,40.7,10.3,40.7,48.2v51.8H260.8z"
            />
            <path
              class="st0"
              d="M340.1,19v27.3h23l1.7,23.7h-24.9v36.8c0,10.3,5.7,13.1,11.1,13c7.6-0.1,11.8-1.5,14.9-3.1l3.4,24.4c-7.1,2.8-12.9,4-20.6,4.4c-21.2,1-37.2-8.3-37.2-38.7V70h-17.4V46.3h17.4V22.2L340.1,19z"
            />
            <path
              class="st1"
              d="M411.4,24.5c0,23.7-36,23.7-36,0S411.4,0.9,411.4,24.5z"
            />
            <polygon
              class="st1"
              points="373.2,56.5 379.4,160.9 407.3,160.9 413.5,56.5"
            />
            <path
              class="st0"
              d="M642.9,45.4l2.2,10.8C651.7,47,660.5,43,671.6,43c9.4,0,17.2,2.8,24.2,9l-12,22.8c-4.8-3.4-9.8-5-15.8-5 c-12.4,0-23,8-23,22.8v51.5h-28.8V45.4H642.9z"
            />
            <path
              class="st0"
              d="M580.2,45.2l-1,11.4C574,47.8,562.4,43,550,43c-23.9-0.3-43.4,12.5-49.7,35.2h91.2l-16.7,6.8l-76.4,2.9 c-0.1,0.9-0.2,1.7-0.2,2.6h76l-16.7,6.8l-59.3,2.3c0.1,1.1,0.2,2.2,0.3,3.2h58.6l-16.7,6.8l-40.5,1.5c6,22.6,24.7,35.6,49,35.4 c9.6-0.2,23.8-4.2,30.4-14.6l1.6,12.4h26c0-32.8,0-66.1,0-98.9H580.2z"
            />
            <path
              class="st0"
              d="M491.5,114c-5,3.7-12.4,5.7-18,5.7c-13.4,0-25.4-9-25.4-24.8c0-16.4,11.6-25.8,26-25.8c5.7,0,12,1.1,17.8,5.3 c3-8.3,7.9-15.1,14.2-20.2c-9.4-8.4-20-11.1-33.2-11.1C444.1,43,419,61.6,419,94.8c0,35.7,25.2,51.5,54.1,51.5 c10.7,0,22.6-2.6,33.2-10.6C499.6,130.4,494.5,123,491.5,114z"
            />
          </svg>
        </a>
      </div>
      <CHeaderNav class="d-md-down-none mr-auto"></CHeaderNav>
      <v-spacer></v-spacer>
      <CHeaderNav class="mr-4">
        <div
          v-if="$store.state.GetServerData.userType === 'admin'"
          v-for="item in navBars"
          :key="item.name"
          class="d-none d-lg-block"
        >
          <CDropdown
            v-if="item.items.length > 0 && controllerRole(item.role)"
            inNav
            class="c-header-nav-items"
            add-menu-classes="mt-2 pt-0"
          >
            <template #toggler>
              <CHeaderNavLink class="h6 m-0 text-primary mr-2">
               
                {{ item.name }}
              </CHeaderNavLink>
            </template>
            <div
              v-for="(subItem, subItemIndex) in item.items"
              :key="subItem.name"
            >
              <CDropdownItem
                v-if="controllerRole(subItem.role)"
                :to="subItem.to"
              >
                {{ subItem.name }}
              </CDropdownItem>

              <div
                style="
                  display: flex;
                  justify-content: center;
                  margin-top: 1rem;
                  border-top: 1px solid #d7dbdd;
                  padding-top: 1rem;
                "
                v-if="
                  item.route == '/reservation' && subItemIndex == navBars.length
                "
              >
                <CInput
                  class="w-50 mr-6"
                  type="text"
                  placeholder="Rez.No"
                  v-model="searchResCode"
                  :value="searchResCode"
                />
                <router-link style="padding: 10px 10px" :to="redirectResCode"
                  >Ara</router-link
                >
              </div>
            </div>
          </CDropdown>
          <CHeaderNavItem v-if="item.items.length < 1" class="mr-2">
            <CHeaderNavLink
              v-if="controllerRole(item.role)"
              :to="item.to"
              exact
              class="h6 m-0 text-primary"
            >
              {{ item.name }}
            </CHeaderNavLink>
          </CHeaderNavItem>
        </div>

        <SelectCurrency />
        <SelectLanguage />
        <DDProfilMenu />
      </CHeaderNav>
    </v-toolbar>
  </div>
</template>

<script>
import DDProfilMenu from "./DDProfilMenu";
import navBarJSON from "./_nav";
import SelectLanguage from "../components/SelectLanguage.vue";
import SelectCurrency from "../components/SelectCurrency.vue";
import Func from "../func";
export default {
  name: "TheHeader",

  components: {
    DDProfilMenu,
    SelectLanguage,
    SelectCurrency,
  },

  data() {
    return {
      projectName: "RentiCar",
      signularRules: this.$store.state.GetServerData.signularRules || [],
      navBars: [],
      searchResCode: "",
      redirectResCode: "",
    };
  },
  created() {
    this.navBars = navBarJSON[0]._children.filter((v) => v.isHeader);
  },
  watch: {
    searchResCode: {
      handler: function (newValue) {
        this.redirectResCode = "/reservation/detail/" + newValue;
      },
      immediate: true,
    },
  },
  methods: {
    controllerRole(props) {
      return Func.controllerRole(props, this.signularRules);
    },
    testa() {
      this.$router.push({
        path: "http://localhost:8081/reservation/index/?startDate=Tue%20Mar%2028%202023%2004%3A01%3A42%20GMT%200300%20%28GMT%2003%3A00%29&&&endDate=Wed%20Mar%2029%202023%2004%3A01%3A42%20GMT%200300%20%28GMT%2003%3A00%29&status=approved",
      });
    },
  },
};
</script>

<style lang="scss">
.notificatin-wr {
  button {
    border: none;
    background: transparent;
    outline: none;
    position: relative;
    span {
      background-color: red;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 11px;
      color: white;
      border-radius: 50%;
    }
  }
  svg {
    fill: #394550;
  }
}
#logo-header {
  width: 127px;
  height: 30px;

  .st0 {
    fill: #003d6a;
  }

  .st1 {
    fill: #e1251b;
  }
}
.v-app-bar-mobile {
  display: none;
  @media (min-width: 992px) {
    display: none;
  }
  @media only screen and (max-width: 992px) {
    display: block;
  }
}
.v-app-bar-window {
  @media (min-width: 992px) {
    display: block;
  }
  @media only screen and (max-width: 992px) {
    display: none;
  }
}
</style>
