var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CDropdown",
        {
          staticClass: "c-header-nav-items",
          attrs: {
            inNav: "",
            placement: "bottom-end",
            "add-menu-classes": "mt-2 pt-0",
          },
          scopedSlots: _vm._u([
            {
              key: "toggler",
              fn: function () {
                return [
                  _c(
                    "CHeaderNavLink",
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["fas", "user-circle"], size: "2x" },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "h6",
            {
              staticClass: "my-1 text-center",
              staticStyle: { padding: "10px" },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.store.name + " " + _vm.store.lastname) + " "
              ),
            ]
          ),
          _c("CDropdownDivider"),
          _c(
            "CDropdownItem",
            {
              nativeOn: {
                click: function ($event) {
                  return _vm.onClickProfile.apply(null, arguments)
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-2",
                attrs: { icon: ["far", "id-card"], size: "lg" },
              }),
              _vm._v(_vm._s(_vm.$t("profil")) + " "),
            ],
            1
          ),
          _c(
            "CDropdownItem",
            {
              nativeOn: {
                click: function ($event) {
                  return _vm.onClickLogout.apply(null, arguments)
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-2",
                attrs: { icon: ["fas", "sign-out-alt"], size: "lg" },
              }),
              _vm._v(_vm._s(_vm.$t("exit")) + " "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }