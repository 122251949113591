export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Ana Sayfa",
        to: "/dashboard",
        icon: "cil-speedometer",
        isHeader: true,
        langSlug: "home_page",
        items: [],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Kullanıcılar",
        route: "/users",
        icon: "cil-user",
        isHeader: true,
        langSlug: "user",
        headerIcon: ["fas", "user"],
        role: [
          "PARTNER_USER_FIND",
          "USER_FIND",
          "ADMIN_FIND",
          "VENDOR_USER_FIND",
          "TESTER_FIND",
          "TC_VERIFY",
          "ADMIN_ROLE_FIND",
        ],

        items: [
          {
            name: "Üyeler",
            to: "/users/member",
            role: "USER_FIND",
            langSlug: "members",
          },
          {
            name: "Yöneticiler",
            to: "/users/admin/",
            role: "ADMIN_FIND",
            langSlug: "managers",
          },
          {
            name: "Partner Kullanıcıları",
            to: "/users/partner/",
            role: ["PARTNER_USER_FIND"],
            langSlug: "partner_users",
          },
          {
            name: "Firma Kullanıcıları",
            to: "/users/vendor",
            role: ["VENDOR_USER_FIND"],
            langSlug: "company_users",
          },
          {
            name: "Test Kullanıcıları",
            to: "/users/tester/",
            role: "TESTER_FIND",
            langSlug: "testers",
          },
          {
            name: "Mernis Kontrol",
            to: "/users/mernis/",
            role: "TC_VERIFY",
            langSlug: "mernis_control",
          },
          {
            name: "Grup Yetkilendirmesi",
            to: "/users/role/",
            role: "ADMIN_ROLE_FIND",
            langSlug: "group_authorization",
          },
          {
            name: "Hesap Silme Talepleri",
            role: "USER_FIND",
            to: "/user-delete-requests/",
            langSlug: "account_deletion_requests",
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Firmalar",
        route: "/vendor/",
        icon: "cil-factory",
        isHeader: true,
        langSlug: "campanies",
        headerIcon: ["fas", "building"],
        role: [
          "VENDOR_FIND",
          "VENDOR_LOCATION_FIND",
          "VENDOR_CAR_FIND",
          "VENDOR_COMMISSION_FIND",
          "VENDOR_STRIKE_THROUGH_TYPE_FIND",
        ],
        items: [
          {
            name: "Firmalar",
            to: "/vendor/",
            role: "VENDOR_FIND",
            langSlug: "campanies",
          },
          {
            name: "Firma Lokasyonları",
            to: "/vendor/locations",
            role: "VENDOR_LOCATION_FIND",
            langSlug: "campanies_location",
          },
          {
            name: "Firma Araçları",
            to: "/vendor/cars",
            role: "VENDOR_CAR_FIND",
            langSlug: "campaines_cars",
          },
        ],
      },

      {
        _name: "CSidebarNavItem",
        name: "Partnerler",
        to: "/partner/",
        icon: "cil-factory",
        isHeader: false,
        langSlug: "partners",
        role: ["PARTNER_FIND"],
        items: [],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Rezervasyonlar",
        route: "/reservation",
        icon: "cil-location-pin",
        isHeader: true,
        langSlug: "reservations",
        headerIcon: ["fas", "bookmark"],
        role: ["RESERVATION_FIND"],

        items: [
          {
            name: "Rezervasyon Yap",
            role: "RESERVATION_CREATE",
            to: "/reservation/new",
            langSlug: "make_a_reservation",
          },

          {
            name: "Hepsi",
            role: "RESERVATION_FIND",
            to: "/reservation/index/",
            langSlug: "all",
          },
          {
            name: "Son Aramalar",
            role: "RESERVATION_FIND",
            to: "/reservation/index/pending",
            langSlug: "last_search",
          },
          {
            name: "Devam Edenler",
            role: "RESERVATION_FIND",
            to: "/reservation/index/active",
            langSlug: "continuning_reservations",
          },
          {
            name: "Gelecekler",
            role: "RESERVATION_FIND",
            to: "/reservation/index/approved",
            langSlug: "future_reservations",
          },
          {
            name: "Geçmişler",
            role: "RESERVATION_FIND",
            to: "/reservation/index/completed",
            langSlug: "past_reservations",
          },

          {
            name: "Erken İade Talepleri",
            role: "RESERVATION_FIND",
            to: "/reservation/index/earlyReturnRequest",
            langSlug: "early_return_req",
          },
          {
            name: "Erken İade Onay Bekleyenler",
            role: "RESERVATION_REFUND_AND_VOID",
            to: "/reservation/index/earlyReturnFinancialConfirmation",
            langSlug: "early_return_req_financial_confirm",
          },
          {
            name: "Erken İadesi Onaylanan Rezervasyonlar",
            role: "RESERVATION_FIND",
            to: "/reservation/index/confirmedEarlyReturn",
            langSlug: "early_return_successed",
          },
          {
            name: "İptaller",
            role: "RESERVATION_FIND",
            to: "/reservation/index/cancelled",
            langSlug: "cancelled_reservations",
          },
          {
            name: "No-Show İptalleri",
            role: "RESERVATION_FIND",
            to: "/reservation/index/noShowCancelled",
            langSlug: "no_show_cancelled_reservations",
          },
          {
            name: "Ödeme Hatalı",
            role: "RESERVATION_FIND",
            to: "/reservation/index/paymentError",
            langSlug: "payment_errors",
          },
        ],
      },

      {
        _name: "CSidebarNavDropdown",
        name: "Muhasebe",
        route: "/accounting/",
        icon: "cil-money",
        isHeader: true,
        langSlug: "accounting",
        headerIcon: ["fas", "location-arrow"],
        items: [
          {
            name: "Faturalar",
            role: "LOCATION_FIND",
            to: "/accounting/invoices/",
            langSlug: "invoices",
          },
          {
            name: "Ödemeler",
            role: "LOCATION_FIND",
            to: "/accounting/payments/",
            langSlug: "payments",
          },
        ],
        role: ["ACCOUNTING_FIND"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Mutabakatlar",
        to: "/consensus/index",
        icon: "cil-bullhorn",
        isHeader: true,
        langSlug: "consensus",
        items: [],
        role: "CONSENSUS_FIND",
      },
      {
        _name: "CSidebarNavItem",
        name: "Araçlar",
        to: "/cars/",
        icon: "cil-car-alt",
        isHeader: true,
        langSlug: "cars",
        role: "CAR_FIND",
        items: [],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Lokasyonlar",
        route: "/locations/",
        icon: "cil-location-pin",
        isHeader: true,
        langSlug: "locations",
        headerIcon: ["fas", "location-arrow"],
        items: [
          {
            name: "Lokasyonlar",
            role: "LOCATION_FIND",
            to: "/locations/",
            langSlug: "locations",
          },
          {
            name: "Şehir Lokasyonları",
            role: "LOCATION_FIND",
            to: "/citylocations/",
            langSlug: "city_locations",
          },
        ],
        role: ["LOCATION_FIND"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Yorumlar",
        to: "/comment/",
        icon: "cil-comment-bubble",
        isHeader: true,
        langSlug: "comments",
        items: [],
        role: "RESERVATION_COMMENT_FIND",
      },
      {
        _name: "CSidebarNavItem",
        name: "Kampanyalar",
        to: "/campaign/",
        icon: "cil-bullhorn",
        isHeader: false,
        langSlug: "campaign",
        role: "RESERVATION_VENDOR_CAMPAIGN_FIND",
        items: [],
      },
      {
        _name: "CSidebarNavItem",
        name: "Kuponlar",
        to: "/coupons/",
        icon: "cil-gift",
        langSlug: "coupons",
        role: "COUPONS_FIND",
        items: [],
        isHeader: false,
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Ayarlar",
        route: "/settings",
        icon: "cil-comment-bubble",
        isHeader: false,
        langSlug: "settings",
        role: "SINGULAR_FIND",
        items: [
          {
            name: "Ayarlar",
            to: "/settings/settings",
            role: "SINGULAR_FIND",
            langSlug: "settings",
          },
          {
            name: "Sorular",
            to: "/settings/questions",
            role: "SINGULAR_FIND",
            langSlug: "questions",
          },
          {
            name: "İş Ortakları",
            to: "/settings/vendor",
            role: "SINGULAR_FIND",
            langSlug: "partners_work",
          },
          {
            name: "Aylık Kiralama Sayfası",
            to: "/settings/monthlyrentalpage",
            role: "SINGULAR_FIND",
            langSlug: "montly_rental_page",
          },
          {
            name: "Sözleşmeler",
            to: "/settings/contracts",
            role: "SINGULAR_FIND",
            langSlug: "contracts",
          },
        ],
      },
    ],
  },
];
