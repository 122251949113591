<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small class="white m-2 caption text-capitalize" v-bind="attrs" v-on="on">
        <v-icon size="18" class="mr-2"> mdi-cash </v-icon>
        <!-- {{ $t("header.select_lang") }} -->
        <b> ({{ selected }}) </b>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        style="min-height: 35px"
        v-for="(item, index) in currencies"
        :key="index"
      >
        <v-list-item-title
          class="caption text-capitalize"
          style="cursor: pointer"
          @click="changeCurrency(item.short)"
          >{{ item.title }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "SelectCurrency",
  data() {
    return {
      currencies: this.getCurrencies(),
      selected: this.$i18n.currency,
    };
  },
  watch: {
    "$i18n.currency": {
      handler: function (val, oldVal) {
        this.currencies = this.getCurrencies();
      },
      deep: true,
    },
  },
  created() {
    const storageCurrency = this.$store.state.currency
    if (storageCurrency) {
      this.selected = storageCurrency;
      this.$i18n.currency = storageCurrency;
    }
  },
  methods: {
    getCurrencies() {
      return [
        { title: "TRY", short: "TRY" },
        { title: "USD", short: "USD" },
        { title: "EUR", short: "EUR" },
      ];
    },
    changeCurrency(value) {
      this.$i18n.currency = value;
      this.selected = value;
      this.$store.commit("setCurrency", value);
      // refresh windows
      this.$router.go();
    },
  },
};
</script>
<style lang="scss"></style>
